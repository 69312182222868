import React, { Component } from "react"
import { Link } from "gatsby"
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from "../components/utilities/SimpleModal"

import "./casestudykddi.scss"

import imgQuote from "../images/quotation_mark.webp"

class VideoLauncher extends Component {

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);
    };
    openModal() {
        this.modalRef.current.handleOpen();
    };


    render() {
        return (
            <div
                onClick={() => this.openModal()} 
                id="videoLauncer"
                className="videoLauncherBtn"
                >
                <img
                    src="https://apiold.meritmile.com/wp-content/uploads/2022/09/kddi-video.png"
                    className="results-image img-fluid"
                    alt="Citrix Google Contact Center Marketing Case Study Challenge"
                    width="390px"
                    style={{ maxWidth: '390px', marginLeft: '20px'}}
                />
                <SimpleModal videoSRC='5Jv9XXSEFdk' innerRef={this.modalRef} />
            </div>
        );
    }
}

export default ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.yoast.title}
      description={pageContext.yoast.metadesc}
      ogimg={pageContext.yoast.opengraph_image}
      link={pageContext.link}
    />
    <div className="case-study-kddi-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div
            className="header-container"
            style={{
              backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
            }}
          >
            <img src={imgQuote} alt="" />
            <h2>{pageContext.acf.header_text}</h2>
          </div>
        </div>
      </div>
      <div className="container detail-wrapper">
        <div className="row">
          <div className="col-md-8">
            <img
              src={
                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                  .fluid.src
              }
              className="client-logo"
              width={pageContext.acf.client_logo.media_details.width}
              height={pageContext.acf.client_logo.media_details.height}
              alt={pageContext.yoast.opengraph_title}
            />
          </div>
          <div className="col-md-4">
            <a href="https://www.meritmile.com/uploads/2022/09/merit-mile-kddi-america-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bar"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="first-paragraph"
              dangerouslySetInnerHTML={{
                __html: pageContext.acf.first_paragraph,
              }}
            />
            {/* <a href="https://www.meritmile.com/uploads/2019/03/quackSkins-and-merit-mile-case-study.pdf" className="btn sitebtn" target="_blank" rel="nofollow noopener noreferrer">Read the Full Case Study</a> */}
          </div>
        </div>
      </div>
      <div className="fullWidthGrayCTA">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ol className="threeColList">
                <li>
                  Cloud and digital
                  <br />
                  transformation
                  <br />
                  services launch
                </li>
                <li>
                  Omnichannel
                  <br />
                  communications
                  <br />
                  services launch
                </li>
                <li>
                  Channel partner
                  <br />
                  program
                  <br />
                  launch
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container detail-wrapper" style={{ marginTop: "2em" }}>
        <div className="row margin-b">
          <div className="col-12">
            <div>
              <p>
                With three significant launches in the 2020 KDDI America
                strategic plan, Merit Mile first created a workstream that was
                designed to deliver against the line of business priorities, but
                also cater to the research and development needs of secondary
                business assets and associated launches nearly simultaneously.
              </p>
            </div>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-12 text-center">
            <img
              src="https://apiold.meritmile.com/wp-content/uploads/2022/09/timeline.png"
              className="into-image"
              width=""
              height=""
              alt={pageContext.yoast.opengraph_title}
              style={{ marginBottom: "2em", maxWidth: "100%" }}
            />
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
            <div>
              <p>
                Given the existing awareness and marketplace footprint of the
                KDDI America datacenter business, it was determined that
                prioritizing the launch of the entity's cloud services business
                made the most practical, tactical, and strategic business sense.
                Similarly, the KDDI America cloud services business would then
                serve not only as the bridge and enabler of datacenter customer
                (and revenue) evolutions, but also the conduit for omnichannel
                communications and channel partner program launches.
              </p>
              <p>
                When successfully executed and landed, this cadence of strategic
                business launches would then firmly support the broader vision
                of KDDI America differentiation by
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <p>
                showcasing a true <b>Dx-to-Cx suite of services and solutions</b>, all designed to
                support existing customer needs and growth while creating
                pipeline and demand for onboarding new, prospective customers.
              </p>
              <p>
                For Merit Mile, the business and marketing orchestration
                challenges were clear: define, design, differentiate, launch,
                and land critical business assets while navigating international
                acceptance from far east corporate leadership, do so amidst the
                global COVID-19 pandemic, and execute on an aggressive schedule.
              </p>
              <p>
                <b>Challenges accepted.</b>
              </p>
            </div>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-12">
            <div>
              <h2 className="blue">
                Launching KDDI America cloud services
                <br />
                with Microsoft, AWS, and Ingram Micro
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              className="second-paragraph"
              dangerouslySetInnerHTML={{
                __html: pageContext.acf.second_paragraph,
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Approach</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>
              For over a decade, Merit Mile has been helping technology
              companies and channel partners develop, launch, and grow their
              product and service offerings. Using a <b>proven and repeatable, Pragmatic Marketing influenced approach</b>, Merit Mile began by workshopping with KDDI America to develop a
              go-to-market strategy in support of the macro-level business,
              sales, and customer goals. Through this exercise, the unique
              differentiators of KDDI America, customer profiles and needs, and
              key use cases were defined.
            </p>
          </div>
          <div className="col-md-6">
            <p>
              During this time, KDDI America and Merit Mile also recruited a
              Microsoft CSP Indirect Provider, also known as a Microsoft Cloud
              Solutions Provider distribution partner. The recruitment
              interviews were rigorous but necessary as the indirect provider
              would need to understand both the Microsoft CSP business as well
              as the AWS cloud services business. After reviewing proposals and
              business pitches from industry leading distributors, Ingram Micro
              was selected as the KDDI America indirect provider of record.
            </p>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-12 text-center">
            <img
              src="https://apiold.meritmile.com/wp-content/uploads/2022/09/ingram.svg"
              className="into-image"
              width=""
              height=""
              alt={pageContext.yoast.opengraph_title}
              style={{ marginBottom: "1em", maxWidth: "100%" }}
            />
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-12">
            <p>
              Ultimately, the outcomes of these exhaustive exercises served as
              the foundation for content creation and ongoing project/launch
              governance which included a:
            </p>
            <ul className="boldList margin-b">
              <li>Go-to-market strategy and execution plan</li>
              <li>Sales enablement strategy and execution plan</li>
              <li>Messaging and positioning framework</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Solutions</h3>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
            <p>
              Once the strategy and messaging were defined, Merit Mile shifted
              into launch mode, developing the digital, marketing,
              communications, and suite of sales and marketing collateral in
              support of the launch. To establish a <b>differentiated and uniquely ownable digital footprint</b> for
              cloud services, www.kddiacloud.com was designed, developed, and hosted by Merit Mile. Owing to KDDI
              America's strong relationship with Asia-Pacific enterprise
              customers operating in the US, the mobile-responsive site was
              designed for localization (L10N) in Japanese. The sites goals are
              direct: <b>tell the why KDDI America for cloud services story</b>,
              educate on high-value use cases, and provide a simple outlet for
              lead conversion. At the product marketing level, Merit Mile also
              authored and produced a <a href="https://www.youtube.com/watch?v=5Jv9XXSEFdk&t=66s" target="_blank" rel="noopener noreferer">video</a>, customer
              presentations, and collateral <a href="https://metrix.meritmile.com/Stage/uploads/Accelerate%20Your%20Hybrid%20and%20Multi-Cloud%20Strategy%20with%20KDDI%20America%20Telehouse_0.pdf" target="_blank" rel="noopener noreferer">brochures</a>.
            </p>
          </div>
          <div className="col-md-6">
            <VideoLauncher />
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-12">
            <p>
              To empower the KDDI America sales team, Merit Mile, in partnership
              with Ingram Micro, designed and developed a cloud services sales
              training program and presented a series of real-time training
              sessions. Hundreds of sellers from across the US were trained by
              Merit Mile to:
            </p>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
            <ul className="normalList">
              <li>Understand the KDDI America cloud services strategy</li>
              <li>Learn about the cloud services offerings from Microsoft and AWS </li>
              <li>Discover how their indirect provider, Ingram Micro, could support their efforts</li>
              <li>Identify the existing and new customer opportunities to land, up-sell, and cross sell</li>
              <li>Communicate and position the high-potential use cases for KDDI America cloud services</li>
            </ul>
            <p>At launch, Merit Mile introduced the US market to the new KDDI America cloud services program through an integrated communications plan, which included industry and analyst outreach, PR, social media, digital awareness, and customer communications. </p>
          </div>
          <div className="col-md-6">
            <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/kddi-solbrief.png"
                alt="KDDI America Case Study Media"
                className="img-fluid results-image"
                style={{ marginLeft: '20px', maxWidth: "100%"  }}
                />
          </div>
        </div>
      </div>
      <div className="fullWidthBluerayCTA">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Results</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>Merit Mile led KDDI America through their entire cloud services launch <b>in only three months</b>, from the strategy workshops all the way to go-live. Within this time span, key highlights included:</p>
              <ul className="normalList">
                <li>Led the entire go-to-market plan</li>
                <li>Launched the new www.kddiacloud.com</li>
                <li>Developed a complete marketing and sales bill-of-materials</li>
                <li>Trained approximately 200 KDDI America sellers</li>
                <li>Established market presence with a press releases and media relations</li>
                <li>Drove awareness with digital, social media, and email nurture campaigns</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container detail-wrapper" style={{ marginTop: "2em" }}>
        <div className="row margin-b">
          <div className="col-12">
            <h2 className="blue">
              Landing the KDDI America omnichannel business<br />
              and creating awareness, thought leadership,<br />
              and pipeline with InfoBip
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Situation</h3>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
            <p>While the KDDI America cloud services business unit entered the US market, Merit Mile commenced the research and development phase of the KDDI America omnichannel business in preparation for the next 2020 strategic milestone. </p>
            <p>Being at the forefront of various industries is not new to KDDI given their historical relevance in telecommunications and technology. And with a significant footprint as a mobile network operator (MNO) with millions of cellular subscribers in Japan, entering the growing industries of advanced messaging, <b>rich communication services (RCS)</b>, and what we'll define as "omnichannel communications" in the US was strategic and important to KDDI America. </p>
          </div>
          <div className="col-md-6">
            <div class="grayBox" style={{ minHeight: "500px"}}>
              <h4>41%</h4>
              <p>B2C organizations say developing a CPaaS strategy is a significant area of focus in the next<br />12-24 months. </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div class="grayBox" style={{ minHeight: "420px"}}>
              <h4>20%-30%</h4>
              <p>IT budget allocated to CPaaS in the next 12-24 months.</p>
            </div>
          </div>
          <div className="col-md-6">
            <p>However, this was not our first foray into this <a href="https://metrix.meritmile.com/Stage/uploads/Merit%20Mile%20and%203C%20Interactive%20Case%20Study.pdf" target="_blank" rel="noopener noreferrer">burgeoning industry</a>. With previous lessons learned, largely related to marketplace education, Merit Mile knew exactly where to start. Industry jargon and acronyms aside, Merit Mile first needed to define or "de-clutter" the category, before carving out KDDI America's role within it. Omnichannel communications or messaging is defined as creating more mobile-centric engagement opportunities for businesses through a <b>Communications Platform-as-a-Service (CPaaS)</b>, and KDDI America was looking to showcase its new offerings in its efforts to serve as the hub of this experience.</p>
          </div>
        </div>
      </div>

      <div className="container detail-wrapper">
        <div className="row margin-b">
          <div className="col-12">
            <p>KDDI America began offering enterprise business customers a leading SaaS API-based advanced messaging solution that is fully customized to help businesses reach their customers with marketing through a litany of multimedia messaging channels. Despite this, KDDI America’s suite of omnichannel offerings, along with its unique approach of leveraging digitally transformative cloud-based technologies for customer messaging solutions was not well-known throughout their targeted industries.</p>
            <p>With data firmly in hand, Merit Mile focused on building a comprehensive go-to-market strategy and awareness campaign for the KDDI America omnichannel business encompassing editorial, thought leadership, analyst briefings and social media content. </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3>Approach</h3>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
            <p>Merit Mile's strategic approach to achieving awareness objectives for clients is driven by the agency's deep experience in developing messaging strategies, identifying unique industry positioning, and its long history of working with editors, reporters, and analysts. <b>Merit Mile worked with KDDI America to build its external narrative to ensure</b> it was emphasizing not only the company's legacy of telecommunications and technology offerings, but also its unique industry offering through the cloud and digital transformation.</p>
            <p>Similar to the cloud services initiative, KDDI America needed a CPaaS provider, to build their omnichannel services with and go-to-market with a uniquely differentiated vision of true Dx-to-Cx solutions.</p>
          </div>
          <div className="col-md-6">
            <p>
                After nearly a dozen interviews and partner evaluations, KDDI America selected InfoBip as the omnichannel services delivery partner.
              </p>
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/infobip.svg"
                className="infobip-image"
                width="500"
                height=""
                alt={pageContext.yoast.opengraph_title}
                style={{ marginTop: "1em", marginBottom: "2em", maxWidth: "100%", }}
              />
              <p>
                With the vision, plan, and now partner selected, a comprehensive go-to-market strategy, demand generation campaign, and awareness program was built.
              </p>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
            <h3>Solutions</h3>
            <p>After solidifying a messaging strategy, communications plan, and targeted media and analysts, Merit Mile issued a press release announcing KDDI America's entrance into the omnichannel communications and dvanced messaging space. KDDI America was positioned as providing a uniquely differentiated omnichannel communications solution for businesses in a number of vertical industries.</p>
            <p>This successful launch created an entryway for KDDI America to author a series of by-lined thought leadership articles that provided in-depth insight into the issues and challenges businesses face in leveraging mobile messaging to reach consumers. It also paved the way for Merit Mile to embark on a series of analyst briefings with KDDI America leadership about the company's unique industry position and service offerings.</p>
          </div>
          <div className="col-md-6">
            <img
              src="https://apiold.meritmile.com/wp-content/uploads/2022/09/kddi-lplogos.png"
              className="kddi-website-image"
              width="683"
              height=""
              alt={pageContext.yoast.opengraph_title}
              style={{ maxWidth: "100%", marginLeft: "20px" }}
            />
          </div>
        </div>      
        <div className="row margin-b">
          <div className="col-12">
            <p>The PR complemented Merit Mile's design and hosting of KDDIAmessaging.com, a site designed to educate and energize businesses and partners interested in working with KDDI America on omnichannel communications initiatives. The site showcases videos, eBook, and infographics about omnichannel communications. Additional thought leadership was created by developing educational content for Tech Target and Tech Forge, heavily trafficked repositories of technology research. </p>
            <p>All of this was bookended by Merit Mile running ongoing <b>demand generation and social media campaigns to drive brand awareness and lead generation</b>. The campaigns further reinforced KDDI America as a leading authority figure in omnichannel communications on LinkedIn, Twitter, Tech Target, and Tech Forge. </p>
          </div>
        </div>
      </div>
      <div className="fullWidthBluerayCTA">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Results</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>Merit Mile's comprehensive external awareness campaign resulted in the following highlights for KDDI America over 12 weeks:</p>
              <ul className="normalList">
                <li>Press release secured in leading tech and business media outlets</li>
                <li>By-lined thought leadership articles secured and placed in 10 targeted trade media web sites, including Telecomm Reseller and Telemedia</li>
                <li>Four analyst briefings completed with IDC, Dell' Oro Group, 451 Research, and Mobile World Live</li>
                <li>More than 325 social media postings on Twitter and LinkedIn</li>
                <li>Total campaign impressions on social media in excess of 35,000</li>
                <li>Approximately 500 total landing page views through social media efforts</li>
                <li>Over 100 marketing qualified leads (MQL) for pipeline development</li>
              </ul>
            </div>
          </div>
          <div className="row">
          <div className="col-md-6">
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/cs-kddi-logos-01.png"
                className="logos-bar"
                width="750"
                height=""
                alt={pageContext.yoast.opengraph_title}
                style={{ marginBottom: "1em", maxWidth: "120%", marginLeft: "-60px" }}
              />
            </div>
            <div className="col-md-6">
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/cs-kddi-logos-02.png"
                className="logos-bar"
                width="750"
                height=""
                alt={pageContext.yoast.opengraph_title}
                style={{ marginBottom: "1em", maxWidth: "120%", marginLeft: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container detail-wrapper" style={{ marginTop: "2em" }}>
        <div className="row margin-b">
          <div className="col-12">
            <h2 className="blue">
              Enabling KDDI America growth through the<br />
              power of the channel
            </h2>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
            <div>
              <h3>Situation</h3>
              <p>
                Having successfully launched cloud and omnichannel communication services into the US market, KDDI America set its sight on growth through scale. With Gartner estimating that IT spending will reach a staggering $3.92 trillion in 2021, with some $1.4 trillion tied to communication services, KDDI America recognized that in order to capture the biggest slice of the pie, they needed to scale the reach of their salesforce through IT channel partners.
              </p>
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/microsoft-logo.png"
                className="microsoft-image"
                width="280"
                height=""
                alt={pageContext.yoast.opengraph_title}
                style={{ marginTop: "1em", marginBottom: "2em", maxWidth: "100%", }}
              />
              <p>
                Merit Mile has been providing <b>strategic advisory, go-to-market strategy, and execution to some the world's largest and strongest channel programs and partners for over a decade</b>. From global scale Microsoft and Citrix channel programs to strategic alliance and regional partners, Merit Mile was well positioned to lead KDDI America through the development of their own channel program. 
              </p>
              <h3 style={{ marginTop: '1em' }}>Approach</h3>
              <p>
                Merit Mile drew from our proven experience in the IT channel to consult KDDI America on the best practices of developing a compelling channel program that resonates in the marketplace. As a relatively new entrant into the US channel market, KDDI America needed to <b>instill a message of maturity, margins, and differentiation</b> in order to attract new partners. Merit Mile advised KDDI America on the programmatic elements of a winning channel program, who to target for recruitment, and ultimately how to launch a channel program. Once the programmatic framework of the KDDI America channel program was defined, Merit Mile turned focus to the go-to-market strategy and launch.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/lifestyle-scaled.jpg"
                className="intro-image"
                width="541"
                height=""
                alt={pageContext.yoast.opengraph_title}
                style={{ marginBottom: "1em", maxWidth: "90%", marginLeft: "20px" }}
              />
            </div>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
             <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/kddi-lp.png"
                className="img-fluid solutions-image"
                width="712"
                height=""
                alt={pageContext.yoast.opengraph_title}
                style={{ marginBottom: "1em" }}
              />
          </div>
          <div className="col-md-6">
            <h3>Solutions</h3>
            <p>As with any new product or service, Merit Mile started at the messaging level, developing a winning value proposition for the channel program. Focus was given to demonstrating how becoming a KDDI America channel partner would dovetail into a channel prospect's existing lines of business, how it would provide a competitive advantage, and of course, what's in it for the partner – growth, revenue, and customers. To showcase the KDDI America channel program, Merit Mile developed the brand identity, brand guidelines, and suite of logos and industry badges for the channel program and associated channel marketing content. </p>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-md-6">
             <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/kddi-advisor.svg"
                className="advisor-icon"
                width="518"
                alt={pageContext.yoast.opengraph_title}
                style={{ marginTop: "1em", marginBottom: "1em", maxWidth: "100%"}}
              />
          </div>
          <div className="col-md-6">
            <img
                src="https://apiold.meritmile.com/wp-content/uploads/2022/09/kddi-solution.svg"
                className="solution-icon"
                width="518"
                alt={pageContext.yoast.opengraph_title}
                style={{ marginTop: "1em", marginBottom: "1em", maxWidth: "100%" }}
              />
          </div>
        </div>
      </div>
      <div className="fullWidthBluerayCTA">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Results</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p>Merit Mile launched the new KDDI America channel program in 2020, less than 3 months from the time of initial planning. The program went live on kddiacloud.com/partners, a site designed by Merit Mile for channel partner recruitment and enablement. Here, prospects learn about the program and service offerings, connect with KDDI America, and begin the program enrollment process. During this time, key highlights included:</p>
              <ul className="normalList">
                <li>Consulted executives on the KDDI America channel program strategy</li>
                <li>Developed the channel program value proposition and messaging</li>
                <li>Defined the partner type classifications</li>
                <li>Designed the branding and creative</li>
                <li>Launched the program microsite</li>
                <li>Developed the <a href="https://www.meritmile.com/uploads/2022/09/KDDI-America-Partner-Program-Overview.pdf" target="_blank" rel="noopener noreferrer">program overview brochure</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>      
      <div className="container detail-wrapper" style={{ marginTop: '2em' }}>
        <div className="row margin-b">
          <div className="col-md-8">
            <h3>Conclusion</h3>
          </div>
          <div className="col-md-4">
            <a href="https://www.meritmile.com/uploads/2022/09/merit-mile-kddi-america-case-study.pdf" className="btn downloadBtn" target="_blank" rel="noopener noreferrer">Download the PDF here</a>
          </div>
        </div>
        <div className="row margin-b">
          <div className="col-12">
            <p>
              It's no secret that the KDDI America, <a href="https://www.channel-ready.com" target="_blank" rel="noopener noreferrer">Channel Ready!</a> and Merit Mile teams accomplished quite a bit in nine months, without letting aggressive timelines nor a global healthcare crisis impact our efforts.
            </p>
            <p>
              Collectively, we learned many valuable business and marketing lessons in this process. Mainly that developing, launching, and landing enterprise-class strategic initiatives on time is largely reliant on the orchestration and synthesis of proper planning and proper execution. 
            </p>
            <p>
            In stand alone or integrated fashion, Merit Mile remains proud of the accomplishments with KDDI America at the program milestone launch level. But even more importantly, the strategy yield has afforded KDDI America a uniquely differentiated and holistically connected value proposition for many years to come. We look forward to KDDI America evolving these freshly launched business assets into positions of sustained category leadership for many years to come.
            </p>
          </div>
        </div>
      </div>
      <div className="fullWidthImageWrapper">
        <img
          src="https://apiold.meritmile.com/wp-content/uploads/2022/09/cs-kddi-footer.jpg"
          alt="Merit Mile Background Divider"
          className="img-fluid"
        />
      </div>
      <div className="fullWidthGrayCTA">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5>
                For more insights or to start a conversation with Merit Mile,
                please email us{" "}
                <a href="mailto:Hello@MeritMile.com?subject=KDDI%20America%20Case%20Study%20Inquiry">
                  <b style={{ color: "#003595", textDecoration: "underline" }}>
                    here
                  </b>
                </a>
                .
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="yourteam-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Your Merit Mile Team</h3>
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-12">
              <div className="team-cards">
                <div className="team-card">
                  <img
                    src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                    alt="Scott Lindars"
                    className="img-fluid"
                  />
                  <h4>Scott Lindars</h4>
                  <p>
                    Director
                    <br />
                    Marketing +<br />
                    Business Strategy
                  </p>
                </div>
                <div className="team-card">
                  <img
                    src="https://www.meritmile.com/uploads/2022/08/js-thumb.jpg"
                    alt="John Sternal"
                    className="img-fluid"
                  />
                  <h4>John Sternal</h4>
                  <p>
                    Partner, PR
                    <br />
                    Social, Research
                    <br />
                    &nbsp;
                  </p>
                </div>
                <div className="team-card">
                  <img
                    src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                    alt="Saul Darville"
                    className="img-fluid"
                  />
                  <h4>Saul Darville</h4>
                  <p>
                    Executive
                    <br />
                    Creative
                    <br />
                    Director
                  </p>
                </div>
                <div className="team-card">
                  <img
                    src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                    alt="Mitch Curry"
                    className="img-fluid"
                  />
                  <h4>Mitch Curry</h4>
                  <p>
                    Project
                    <br />
                    Manager
                    <br />
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fullWidthImageWrapper">
        <img
          src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
          alt="Merit Mile Icon Divider"
          className="img-fluid"
        />
      </div>
      <div className="about-wrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>About Merit Mile</h2>
            </div>
          </div>
          <div className="row margin-b">
            <div className="col-md-6">
              <p>
                Established in 2007 in Boca Raton, FL, Merit Mile is a marketing
                services provider focused on helping companies and corporate
                marketing departments build brands, drive sales revenue, and
                garner publicity in their respective industries. Our unique
                differentiator at Merit Mile is our ability to 100% own the
                message, the publicity, and the demand generation aspects of the
                corporate marketing function. Clients hire us and rehire us for
                our ability to refine their messaging/value proposition, develop
                top and mid-funnel leads, and get the type of editorial
                publicity that differentiates them from their competitors. From
                branding to PR to demand generation, Merit Mile is the
                high-octane fuel that invigorates corporate growth engines. To
                learn more, please email us at Hello@MeritMile.com, call us at
                1-561-362-888, or visit us at www.MeritMile.com.{" "}
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="img-fluid"
                src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg"
                alt="Merit Mile Headquarter in Boca Raton, FL"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container quote-wrapper">
        <div className="row">
          <div className="col-12">
            <p>
              For more insights and to connect
              <br />
              with Merit Mile{" "}
              <a href="mailto:Hello@MeritMile.com?subject=Citrix%20%20Google%20ChromeOS%20DaaS%20Case%20Study%20Inquiry">
                <b style={{ color: "black" }}>email us here</b>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
